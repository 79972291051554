import { ImageLoaderProps } from 'next/image';

const cdnImageHost = process.env.NEXT_PUBLIC_CDN_IMAGE_HOST || '';

// https://developers.cloudflare.com/images/image-resizing/integration-with-frameworks/
const CloudflareLoader = ({ src, width, quality }: ImageLoaderProps) => {
    // https://developers.cloudflare.com/images/image-resizing/url-format/#format
    let params = ['format=auto'];

    if (!src.endsWith('.svg')) {
        params = [...params, `width=${width}`];
    }

    if (quality) {
        params = [...params, `quality=${quality}`];
    }

    const paramsString = params.join(',');

    return `${cdnImageHost}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};

const normalizeSrc = (src: string) => (src[0] === '/' ? src.slice(1) : src);

export { CloudflareLoader };
